body, #root {
  margin: 0;
  overflow: hidden;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100vw;
  height: 100vh;
  display: block;
  position: relative;
}

/* In order to limit the functionality of the LayerManager (i.e. adding/deleting layers)
* the buttons to do so are hidden
*/
div[data-testid=layerManagerWindow] button[data-testid=addLayersButton] {
  display: none;
}

div[data-testid=layerManagerWindow] button[data-testid=deleteButton] {
  display: none;
}

div[data-testid=layerManagerWindow] button[data-testid=openMenuButton] {
  display: none;
}
